import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from './utils/auth.js'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login','/changePwd']

function isNull(obj) {
  return obj !== null && obj !== '' && obj !== 'undefined';
}

const title = "总包部"

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = title + '-' +to.meta.title || title;
  const hasToken = getToken()
  if (isNull(hasToken)) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      //判断无用户信息,一般做动态路由
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
