<template>
  <div class="sideItem" v-if="!item.hidden">
    <template
      v-if="hasOnlyChild(item.children, item) &&(!childItem.children || childItem.noChild)">
      <router-link v-if="childItem.meta" :to="resolvePath(childItem.path)">
        <el-menu-item :index="resolvePath(childItem.path)">
          <i style="color:#ffff;margin-right:10px;" :class="childItem.meta.icon ? childItem.meta.icon : ''"></i>
          <span style="color:#ffff" slot="title">{{ childItem.meta.title }}</span>
        </el-menu-item>
      </router-link>
    </template>

    <el-submenu v-else :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <i style="color:#ffff;margin-right:10px;"  :class="item.meta.icon ? item.meta.icon : ''"></i>
        <span style="color:#ffff">{{ item.meta.title }}</span>
      </template>
      <menu_item class="item"
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :basePath="resolvePath(child.path)"
      ></menu_item>
    </el-submenu>
  </div>
</template>

<script>
import { isAbsolutePath } from '@/utils/validate'
import path from 'path'
export default {
  name: 'menu_item',
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      childItem: null
    }
  },
  methods: {
    hasOnlyChild(children = [], item) {
      let newChildren = children.filter(obj => {
        if (obj.hidden) {
          return false
        } else {
          return true
        }
      })
      if (newChildren.length === 1 && !item.meta) {
        this.childItem = newChildren[0]
        return true
      }
      if (newChildren.length === 0) {
        this.childItem = { ...item, path: '', noChild: true }
        return true
      }
      return false
    },
    resolvePath(router) {
      if (isAbsolutePath(router)) {
        return router
      }
      if (isAbsolutePath(this.basePath)) {
        return this.basePath
      }
      return path.join(this.basePath, router)
    }
  }
}
</script>
<style lang="scss" scoped>
.item{
  border-top:1px solid #ffff ;
  border-bottom:1px solid #ffff ;
}
</style>
