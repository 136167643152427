<template>
  <div class="left-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'left-content'
}
</script>

<style lang="scss" scoped>
.left-content{
  width: 250px;
  height: 100%;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  margin-right: 20px;
  overflow: auto;
}
</style>
