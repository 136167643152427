<template>
  <div class="home">
    <Header />
    <Slider />
    <Main />
  </div>
</template>

<script>
import Header from './header';
import Main from './main';
import Slider from './slider';

export default {
  name: 'Layout',
  components:{
    Header,Main,Slider
  }
}
</script>

<style scoped>

</style>
