export const getToken = () => {
  return localStorage.getItem('token') || ''
}

export const setToken = (token) => {
  localStorage.setItem('token', token)
}

export const removeToken = () => {
  localStorage.removeItem("token")
}

export const setUserInfo = (userInfo) => {
  return localStorage.setItem('userInfo',JSON.stringify(userInfo));
}

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo') || '')
}
