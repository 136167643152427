/**
 *  校验是否为绝对路径
 */
export function isAbsolutePath(path) {
  return /^(https?|tel|mailto)/.test(path)
}


/**
 * 校验手机号
 */

export function isPhoneNum(number) {
  return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(number);
}

export  function isNumber(val){
  return /^[0-9]*$/.test(val);
}
