<template>
  <div class="header">
    <div class="header-left">
      <div class="login-image">
       <img src="@/assets/images/logo.png" alt="图片">
      </div>
      <span>党员安全质量监督岗管理系统</span>
    </div>
    <div class="header-right">
     <UserDropDown></UserDropDown>
    </div>
  </div>
</template>

<script>
import UserDropDown from '@/components/UserDropDown'
export default {
  name: "Header",
  components: { UserDropDown },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background-color: #DF1D1D;
  color: #fff;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-left {
    margin-left: 20px;
    display: flex;
    .login-image {
      height: 48px;
      width: 48px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      margin-left: 30px;
      min-width: 297px;
      height: 38px;
      font-size: 27px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 38px;
      color: #FFFFFF;
      opacity: 1;
    }
  }

  .header-right {
    margin-right: 20px;
  }
}
</style>
