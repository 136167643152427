<template>
  <div class="slider">
    <div class="silder-title">
      <div class="title-image">
        <img src="@/assets/images/logo.png" alt="图片">
      </div>
      <div class="title-bottom">
        <span style="text-align:center;margin:0 5px">{{ userInfo.name }}</span>
        <span style="font-size: 14px;margin-top: 10px">({{ userInfo.people_name }})</span>
      </div>
    </div>
    <div class="slider-menu">
      <menu-bar></menu-bar>
    </div>
  </div>
</template>

<script>
import MenuBar from '@/components/MenuBar'
export default {
  name: 'Slider',
  data(){
    return {
      userInfo:""
    }
  },
  created () {
    const info = localStorage.getItem("userInfo") || ''
    if(info) {
      this.userInfo = JSON.parse(info);
    }
  },
  components:{
    MenuBar
  }
}
</script>

<style lang="scss" scoped>
.slider {
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 80px;
  box-sizing: border-box;
  z-index: 11;
  display: flex;
  flex-direction: column;
  .silder-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 262px;
    background-color: #E32322;
    .title-image{
      width: 88px;
      height: 90px;
      overflow: hidden;
      margin-top: 36px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .title-bottom{
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      font-family: PingFang SC;
      color: #FFFFFF;
    }
  }
  .slider-menu{
    background-color: #f0f0f0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E32322;
    h3{
      margin-top: 10px;
      margin-bottom: 30px;
      font-weight: bold;
    }
  }
}
</style>
