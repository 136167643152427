<template>
  <div class="main">
    <div class="main-title">
      <span>{{ currentTitle }}</span>
      <el-button class="backBtn" type="mini" @click="$router.back()">返回</el-button>
    </div>
    <div class="main-content">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  computed: {
    currentTitle() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 100px 20px 0 220px;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100vw;
  .main-title {
    position: fixed;
    z-index: 9;
    top: 80px;
    left: 200px;
    right: 0;
    height: 49px;
    line-height: 49px;
    padding-left: 30px;
    background: #FFFFFF;
    button{
      width: 76px;
      height: 36px;
      background: #E32322;
      border-radius: 4px;
      position: absolute;
      right: 20px;
      top:50%;
      transform: translateY(-50%);
      color: #fff;
    }
    span{
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 0px;
      color: #333333;
      opacity: 1;
      padding-bottom: 12px;
      border-bottom: 3px solid red;
    }
  }
  .main-content {
    margin-top: 40px;
    width: 100%;
    margin-bottom: 10px;
    flex: 1;
    background-color: #f8f8f8;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
</style>
