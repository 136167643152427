import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './premt';

import './assets/styles/index.scss'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import './assets/icon/css/iconfont.css';

import LeftContent from './views/commonViews/LeftContent'
import RightContent from './views/commonViews/LeftContent'

Vue.component(LeftContent)
Vue.component(RightContent)

import './icons'

import { Message,MessageBox } from 'element-ui';

Vue.prototype.$success = Message.success;
Vue.prototype.$error = Message.error;

Vue.prototype.$open = option => {
  return new Promise((resolve,reject) => {
    MessageBox.confirm(option.msg,{
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      resolve(true)
    }).catch(() => {
      //  Message.info(option.cancelText || '取消')
      resolve(false)
    })
  })
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
